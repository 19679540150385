import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	AccuracyKey,
	PrescriptionContactLensesSingleEye,
} from '../../model/prescription'

import {
	CLMeasureValueSingleEye,
	LensData,
	LensDataSingleEye,
	PrescriptionContactLenses,
} from '../../model/prescription'
import {
	EyeDataRow,
	EyeTableBox,
	FirstLine,
	LensDataType,
	LensDataTypeWrapper,
	SecondLine,
	TableTitle,
	TitleWrapper,
	Wrapper,
} from './CommonComponents'
import styled from 'styled-components'
import { selectRxTypeTitle } from '../../features/coremedia/selectors'
import { useSelector } from 'react-redux'
import {
	returnFormattedValueForAxis,
	returnPlusSignedValue,
} from '../../libs/utils'
import { pxToRem } from '../../libs/style'
import { selectExam } from '../../features/exam/selectors'

export const LenseImage = styled.img<{ src: string }>`
	src: ${props => props.src};
	width: ${pxToRem(342)}rem;
	height: auto;
	margin: ${pxToRem(11)}rem ${pxToRem(267)}rem ${pxToRem(42)}rem
		${pxToRem(245)}rem;
	object-fit: contain;
`

const ImgWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const StyledImg = styled.img`
	object-fit: contain;
	max-height: 30vh;
`

const StyledSecondLine = styled(SecondLine)`
	margin-bottom: 0px;
`

const Prescription: React.FC<{
	prescriptionType: string
	accuracy: AccuracyKey
	lensData: LensData
}> = ({ prescriptionType, lensData, accuracy }) => {
	const { t } = useTranslation()
	const { exam } = useSelector(selectExam)
	const rxTypeTitle = useSelector(selectRxTypeTitle(prescriptionType))
	if (!lensData || !exam) return null

	const mapData = (data: LensDataSingleEye) => {
		return [
			{
				label: 'app.Sphere',
				value: data?.sphere as string,
			},
			{
				label: 'app.Cylinder',
				value: data?.cyl as string,
			},
			{
				label: 'app.Axis',
				value: data?.axis ? returnFormattedValueForAxis(data?.axis) : '-',
			},
			{
				label: data?.hOrient
					? data?.hOrient === 'BI'
						? 'app.PrismBI'
						: 'app.PrismBO'
					: 'app.Prism',
				value: data?.hPrism
					? returnPlusSignedValue({
							value: data?.hPrism,
							defaultValue: '-',
							omitPlusSign: true,
					  })
					: '-',
			},
			{
				label: data?.vOrient
					? data?.vOrient === 'BD'
						? 'app.PrismBD'
						: 'app.PrismBU'
					: 'app.Prism',
				value: data?.vPrism
					? returnPlusSignedValue({
							value: data?.vPrism,
							defaultValue: '-',
							omitPlusSign: true,
					  })
					: '-',
			},
			{
				label: 'app.Add',
				value: data?.add
					? returnPlusSignedValue({ value: data?.add, defaultValue: '-' })
					: '-',
			},
		]
	}

	return (
		<Wrapper>
			<TitleWrapper>
				<FirstLine>{t('app.prescriptionType')}</FirstLine>
				<SecondLine>{rxTypeTitle}</SecondLine>
			</TitleWrapper>
			<EyeTableBox>
				<TableTitle>{t('app.prescriptionTitle')}</TableTitle>
				<EyeDataRow eye={'OD'} data={mapData(lensData[accuracy]!.OD)} />
				<EyeDataRow eye={'OS'} data={mapData(lensData[accuracy]!.OS)} />
			</EyeTableBox>
		</Wrapper>
	)
}

export const PrescriptionCL: React.FC<{
	prescriptionType: string
	data: PrescriptionContactLenses
}> = ({ prescriptionType, data }) => {
	const { t } = useTranslation()
	const { exam } = useSelector(selectExam)
	if (!data || !exam) return null

	const mapData = (data: CLMeasureValueSingleEye) => {
		return [
			{
				label: 'app.BaseCurve',
				value: data?.baseCurve ?? '-',
			},
			{
				label: 'app.Diameter',
				value: data?.diameter ?? '-',
			},
			{
				label: 'app.Sphere',
				value: data?.sphere ?? '-',
			},
			{
				label: 'app.Cylinder',
				value: data?.cylinder ?? '-',
			},
			{
				label: 'app.Axis',
				value: data?.axis ? returnFormattedValueForAxis(data?.axis) : '-',
			},
			{
				label: 'app.Add',
				value: data?.addition
					? returnPlusSignedValue({ value: data?.addition, defaultValue: '-' })
					: '-',
			},
		]
	}

	return (
		<Wrapper>
			<TitleWrapper>
				<FirstLine>{t('app.prescriptionType')}</FirstLine>
				<SecondLine>{t(prescriptionType)}</SecondLine>
			</TitleWrapper>
			<EyeTableBox>
				<EyeDataRow eye={'OD'} data={mapData(data.OD?.Measure || {})} />
				<EyeDataRow eye={'OS'} data={mapData(data.OS?.Measure || {})} />
			</EyeTableBox>
		</Wrapper>
	)
}

export const PrescriptionCLType: React.FC<{
	prescriptionType: string
	data: PrescriptionContactLenses
}> = ({ prescriptionType, data }) => {
	const { t } = useTranslation()
	const { exam } = useSelector(selectExam)
	if (!data || !exam) return null

	const mapDataLensType = (data: PrescriptionContactLensesSingleEye) => {
		return {
			LensType: {
				label: 'app.LensType',
				value: data?.LensProduct ? data?.LensProduct : data?.LensType,
			},
			ReplacementFrequency: {
				label: 'app.ReplacementFrequency',
				value: data?.Replenishment
					? data.Replenishment
					: data?.ReplacementFrequency,
			},
			Cleaning: {
				label: 'app.Cleaning',
				value: data?.DisinfectingSolution
					? data.DisinfectingSolution
					: data?.Cleaning,
			},
		}
	}

	return (
		<Wrapper>
			<TitleWrapper>
				<FirstLine>{t('app.lensRecommendationType')}</FirstLine>
				<StyledSecondLine>{t(prescriptionType)}</StyledSecondLine>
			</TitleWrapper>
			<ImgWrapper>
				<StyledImg src="/assets/images/contact-lens.webp" alt="contact-lens" />
			</ImgWrapper>
			<LensDataTypeWrapper isHorizontal={!data.useSameProduct}>
				<LensDataType
					isSameProduct={data.useSameProduct}
					eye={'OD'}
					data={mapDataLensType(data.OD || {})}
				/>
				{!data.useSameProduct && (
					<LensDataType
						isSameProduct={data.useSameProduct}
						eye={'OS'}
						data={mapDataLensType(data.OS || {})}
					/>
				)}
			</LensDataTypeWrapper>
		</Wrapper>
	)
}

export default Prescription
